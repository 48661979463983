import React from "react";
import PageWrapper from "../wrapper";
import { Box, Button, Card, CardContent, Link, Typography } from "@mui/material";
import { theme_dark, theme_light } from "../../../theme";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { volunteer_txt, volunteer_exp } from '../website-text'
import { work_exp, work_txt } from "../website-text";
import { LinkedIn } from "@mui/icons-material";

const all_exp = [
  {
    title: 'Work Experience', 
    data: work_exp, 
    description: work_txt
  },
  {
    title: 'Volunteer Experience', 
    data: volunteer_exp, 
    description: volunteer_txt
  },
];

const COMPANY_LINK = (props) => {
  const link = props.link;
  const company = props.company;
  return (
    <>
      { (!!link) ? 
        <Button 
          href={link} 
          target="_blank" 
          rel="noreferrer" 
          sx={{
            color: theme_dark, 
            border: `1px solid ${theme_dark}`,
            marginBottom: '1em',
            '&:hover': {
              color: theme_light,
              backgroundColor: theme_dark,
            }
          }}
        >
          <LinkedIn sx={{marginRight: '10px'}}/>
          {company}
        </Button>
        :
        <Button 
          disabled 
          variant="outlined" 
          style={{ color: theme_dark, borderColor: theme_dark, marginBottom: '1em' }}
        >{company}</Button>
      }
    </>
  );
}

const COMPANY_TITLE = (props) => {
  return (
    <>
      <Typography variant="h5">{props.job_title}</Typography>
      <Typography variant="p" sx={{display: 'flex', marginBottom: '10px'}}>{props.time}</Typography>
    </>
  );
}

const Experience = () => {
  return (
    <PageWrapper>
      <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '2em'}}>
        <Typography variant="h3">Experiences</Typography>
      </Box>
      {all_exp.map((section, index) => (
        <Box key={index} sx={{display: 'flex', flexDirection: 'column', textAlign: 'start', marginBottom: '2em'}}>
          <Typography variant="h4" sx={{marginBottom: '5px'}}>{section.title}</Typography>
          <Typography sx={{marginBottom: '2em'}}>{section.description}</Typography>
          {section.data.map((exp, index_2) => (
            <Card key={index_2} sx={{color: theme_dark, backgroundColor: theme_light, marginBottom: '1em'}}>
              <CardContent>
                <COMPANY_TITLE job_title={exp.job_title} link={exp.company_link} company={exp.company} time={exp.time} />
                <COMPANY_LINK link={exp.company_link} company={exp.company} />
                <Typography>{exp.description}</Typography>
                {exp.accomplished.map((item, index_3) => (
                  <Typography key={index_3} sx={{display: 'flex', alignItems: 'center', margin: '5px 0'}}><ArrowRightIcon/>{item}</Typography>
                ))}
              </CardContent>
            </Card>
          ))}
        </Box>
      ))}
    </PageWrapper>
  );
}

export default Experience;