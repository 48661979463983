import HomeIcon from '@mui/icons-material/Home';
import Face6Icon from '@mui/icons-material/Face6';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ExploreIcon from '@mui/icons-material/Explore';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

import Home from './components/pages/home'
import About from './components/pages/about'
import Skills from './components/pages/skills'
import Experience from './components/pages/experience'
import Projects from './components/pages/projects'
import Contacts from './components/pages/contact'

export const route_options = [
  {
    title: 'Home',
    icon: HomeIcon, 
    route: '/home',
    element: <Home />
  },
  {
    title: 'About',
    icon: Face6Icon, 
    route: '/',
    element: <About />
  },
  {
    title: 'Skills',
    icon: AutoStoriesIcon, 
    route: '/skills',
    element: <Skills />
  },
  {
    title: 'Experience', 
    icon: BusinessCenterIcon, 
    route: '/experience',
    element: <Experience />
  },
  {
    title: 'Projects',
    icon: ExploreIcon, 
    route: '/projects',
    element: <Projects />
  },
  {
    title: 'Contacts',
    icon: AlternateEmailIcon, 
    route: '/contacts',
    element: <Contacts />
  },
];