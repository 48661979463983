import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HelpIcon from '@mui/icons-material/Help';
import { theme_dark, theme_light } from './theme';
import Sidebar from './components/sidebar';
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { route_options } from './routes';
import Error404 from './components/pages/404';
import Help from './components/help';
import { Button } from '@mui/material';

const drawerWidth = 200;

const DividerCustom = () => (<Divider sx={{backgroundColor: theme_light}}/>);
const SpacerCustom = () => <Box sx={{flexGrow: 1}}></Box>;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  margin: theme.spacing(1, 0),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


function App(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openHelp, setOpenHelp] = React.useState(false);

  const handleToggle = () => {
    setOpen(!open);
    theme.direction = open? 'rtl' : 'ltr';
  };

  const helpToggle = () => setOpenHelp(true);

  const paper_prop = {sx: { backgroundColor: theme_dark, color: theme_light, borderRightStyle: 'solid', borderColor: theme_light }};
  const iconSx = {
    color: theme_light,
    ':hover': {
      backgroundColor: theme_light,
      color: theme_dark,
    },
  }
  const helpSx = {
    width: '100%',
    color: theme_light,
    borderColor: theme_light, 
    ':hover': {
      backgroundColor: theme_light,
      color: theme_dark,
      borderColor: theme_dark, 
    },
  }

  const noSelect = {
    WebkitTouchCallout: 'none', /* iOS Safari */
      WebkitUserSelect: 'none', /* Safari */
       KhtmlUserSelect: 'none', /* Konqueror HTML */
         MozUserSelect: 'none', /* Old versions of Firefox */
          msUserSelect: 'none', /* Internet Explorer/Edge */
            userSelect: 'none', /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }

  return (
    <Router>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        <Drawer variant="permanent" open={open} PaperProps={paper_prop}>
          <DrawerHeader sx={{display: 'flex', justifyContent: 'center'}}>
            <Typography sx={{fontSize: 'x-large', ...noSelect}}>MM</Typography>
          </DrawerHeader>

          <DividerCustom />
          
          <SpacerCustom />
          
          <DividerCustom />
          
          <Sidebar open={open} divider={DividerCustom} />

          <SpacerCustom />
          
          <DrawerHeader sx={{justifyContent: open? 'flex-end' : 'center' }}>
            <IconButton onClick={handleToggle} sx={iconSx}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          
          <DividerCustom />
          
          <DrawerHeader sx={{justifyContent: 'center'}}>
            {open? 
              <Button onClick={helpToggle} sx={helpSx} variant="outlined">MORE</Button> 
              : 
              <IconButton onClick={helpToggle} sx={iconSx}><HelpIcon/></IconButton>
            }
          </DrawerHeader>

        </Drawer>

        <Box component="main" sx={{ display: 'flex', minHeight: '100vh', width: '100%' }}>
          <Routes>
            {route_options.map(route => (
              <Route key={route.route} path={route.route} element={route.element}></Route>
            ))}
            <Route path='*' element={<Navigate to='/'/>}></Route>
          </Routes>
        </Box>
      </Box>
      <Help open={openHelp} setOpen={setOpenHelp} />
    </Router>
  );
}

export default App;
