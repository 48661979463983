import EmailIcon from "@mui/icons-material/Email";
import LinkedIn from "@mui/icons-material/LinkedIn";

///////////////////////////////////////////////////////////////////////////////
//////////////////////////////// MISCELLANEOUS ////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

export const EMAIL = "marzuk.mashrafe@uwaterloo.ca";
export const LINKEDIN = "https://www.linkedin.com/in/marzuk-mashrafe/";


///////////////////////////////////////////////////////////////////////////////
////////////////////////////////     404      /////////////////////////////////
///////////////////////////////////////////////////////////////////////////////



///////////////////////////////////////////////////////////////////////////////
////////////////////////////////    ABOUT     /////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

export const paragraph_1 = `
  My name is Marzuk and I'm currently pursuing a Bachelor's degree in Computer 
  Science at the University of Waterloo, where I'm diving deep into the world 
  of algorithms, data structures, operating systems, and more. My time at the 
  university has not only shaped my technical skills but also strengthened my 
  problem-solving and critical-thinking abilities.
`;

export const paragraph_2 = `
  I've had the privilege of working at some remarkable companies, including 
  Ford Motor Company, Cineplex and OpenText, where I've contributed as a 
  software developer intern. These experiences have equipped me with a solid 
  foundation in full-stack development, software engineering, and agile 
  practices, allowing me to tackle real-world challenges and develop scalable 
  solutions.
`;

export const paragraph_3 = `
  What makes me unique is not just my professional background but also my 
  diverse set of interests that keep me inspired and grounded. Whether 
  it's the adrenaline rush from riding my motorcycle, the discipline and 
  strength I build through Muay Thai, or the strategic play on the 
  badminton court, I believe in embracing life to the fullest. I'm also a 
  fan of rock climbing for its mental and physical challenge, and ballroom 
  dancing for the rhythm and connection it brings.
`;

export const paragraph_4 = `
  I'm always open to connecting with like-minded individuals, collaborating 
  on exciting projects, or discussing anything from tech to motorcycles. 
  If you'd like to get in touch, reach out! Let's create something amazing 
  together.
`;


///////////////////////////////////////////////////////////////////////////////
////////////////////////////////   CONTACT    /////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

export const CONTACT_TEXT_PARAGRAPH_1 = `
  If you have any questions, project proposals, or exciting opportunities you'd
  like to discuss, feel free to reach out to me. I'm always eager to connect with
  fellow tech enthusiasts, potential collaborators, or anyone who shares a passion
  for the digital world.
`;

export const CONTACT_TEXT_PARAGRAPH_2 = `
  I strive to respond to all inquiries promptly, typically within a day or two. 
  You can get in touch with me through any of the following - `;

export const CONTACT_TEXT_PARAGRAPH_3 = `
  Looking forward to hearing from you and exploring new possibilities together!
`;

export const CONTACTS = [
  {
    link: `mailto:${EMAIL}`, 
    text: "Email", 
    icon: <EmailIcon />,
  },
  {
    link: LINKEDIN, 
    text: 'LinkedIn', 
    icon: <LinkedIn />,
  },
];

///////////////////////////////////////////////////////////////////////////////
////////////////////////////////  EXPERIENCE  /////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

export const volunteer_txt = `
  I am a PR in Canada and came here in 2019. 
  When I came here, I was unaware of almost everything 
  (e.g how things work, what people are like etc.). 
  However, without a second thought, 
  people in the community welcomed us with open arms. 
  They helped us with everything that we had problems with. 
  I am truly grateful for that.
  
  I think without the help from others', 
  I wouldn't have been able to be where I am today. 
  That is why I also want to help others in a similar way. 
  If I can help those in need, I want to be able to do that. 
  The best way I can do that is through volunteering for organizations.

  That is why I have been involved in my community for over years now. 
  I have worked over 200+ hours across many organizations helping others 
  in my community. I wish to keep doing the same.
`;

export const volunteer_exp = [
  // {
  //   job_title: '', 
  //   time: 'Sep 2022 - Dec 2022',
  //   company: '',
  //   company_link: '',
  //   description: '', // TODO
  //   accomplished: [], // TODO
  // },
];

export const work_txt = `
  These are some notable experiences I have working in various 
  different projects and roles:
`;

export const work_exp = [
  {
    job_title: 'Co-op Software Developer (.NET)', 
    time: 'Sep 2024 - Dec 2024',
    company: 'Cineplex Entertainment LP',
    company_link: 'https://www.linkedin.com/company/cineplex-entertainment/',
    description: '', // TODO
    accomplished: [], // TODO
  },
  {
    job_title: 'Software Engineer Intern', 
    time: 'Jan 2024 - Apr 2024',
    company: 'Ford Pro',
    company_link: 'https://www.linkedin.com/company/ford-pro/',
    description: '', // TODO
    accomplished: [], // TODO
  },
  {
    job_title: 'Full Stack Developer', 
    time: 'Sep 2022 - Dec 2022',
    company: 'OpenText',
    company_link: 'https://www.linkedin.com/company/opentext/',
    description: '', // TODO
    accomplished: [], // TODO
  },
  {
    job_title: 'Software Developer (Part-Time)', 
    time: 'May 2022 - Present',
    company: 'UbiLab - University of Waterloo',
    company_link: 'https://www.linkedin.com/company/ubilab-university-of-waterloo/',
    description: '', // TODO
    accomplished: [], // TODO
  },
  {
    job_title: 'Software Developer', 
    time: 'Jan 2022 - Apr 2022',
    company: 'UbiLab - University of Waterloo',
    company_link: 'https://www.linkedin.com/company/ubilab-university-of-waterloo/',
    description: '', // TODO
    accomplished: [], // TODO
  },
  {
    job_title: 'Frontend Developer', 
    time: 'May 2021 - Sep 2021',
    company: 'Migrations.ml',
    company_link: 'https://www.linkedin.com/company/migrations-ml/',
    description: '', // TODO 
    accomplished: [], // TODO
  },
  {
    job_title: 'AI Research Project Team Member', 
    time: 'May 2021 - Aug 2021',
    company: 'University of Waterloo/ Microsoft Corporation',
    company_link: 'https://www.linkedin.com/school/uwaterloo/',
    description: '', // TODO
    accomplished: [], // TODO
  },
  {
    job_title: 'Director of Finance', 
    time: 'Nov 2019 - Apr 2020',
    company: 'A[wear]ness',
    company_link: '',
    description: `
      Participated in the annual JA Company Program to create a company 
      (A[wear]ness) and was the Director of Finance for said company. We 
      sold hand-made, eco-friendly friendship bracelets with various 
      themes. As the Director of Finance I performed many of the financial 
      duties of the company including:
    `, 
    accomplished: [
      'Created Financial Plan for the Company. (With the help of advisors)',
      'Raised Capital through investments.',
      'Allocated budget for various departments within the Company.',
      'Kept record of all investments, assets and expenses. (i.e. Bookkeeping)',
      'Helped with liquidation and distributing returns to all investors.',
    ],
  },
];


///////////////////////////////////////////////////////////////////////////////
////////////////////////////////     HOME     /////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

// export const HOME_TITLE = 'Greetings, Adventurer!';
export const HOME_TITLE = 'Welcome to my Portfolio!';
export const HOME_BODY = `
  Hi, I'm Marzuk Mashrafe — a Computer Science student at the University of
  Waterloo with a passion for technology, adventure, and continuous learning.
  This is my little corner of the internet where I share my journey, my work,
  and my diverse interests. Below, you will find my portfolio divided into
  sections:`
;

///////////////////////////////////////////////////////////////////////////////
////////////////////////////////   PROJECTS   /////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

export const projects_txt = `
  In this section of my portfolio, you will find a selection of projects 
  that I have worked on over the years. 
  These projects showcase my skills and abilities as a software developer, 
  and demonstrate my ability to solve complex problems and build functional 
  and effective systems. 
  Whether working on a solo project or as part of a team, 
  I am always striving to learn and improve my skills, 
  and to produce high-quality work.
`;

export const projects = [

];

///////////////////////////////////////////////////////////////////////////////
////////////////////////////////    SKILLS    /////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

export const SKILLSET = [
  {
    title: 'Programming Languages', 
    skills: [
      'JavaScript', 'TypeScript', 'C/C++', 'Python', 'Java', 'HTML', 'CSS/Sass', 
    ],
  },
  {
    title: 'Frontend Development', 
    skills: [
      'Angular', 'AngularJS', 'React', 'Bootstrap', 'Material-UI', 'CoreUI', 'Nivo'
    ],
  },
  {
    title: 'Backend Development', 
    skills: [
      'NodeJS', 'NestJS', 'TypeORM', 'PassportJS', 'JWT', 'Spring Boot', 'socket.io'
    ],
  },
  {
    title: 'Databases', 
    skills: [
      'MySQL'
    ],
  },
  {
    title: 'DevOps and Cloud', 
    skills: [
      'Docker', 'Tekton', 'Terraform', 'Azure', 'GCP (Google Cloud Platform)'
    ],
  },
  {
    title: 'Version Control & Collaboration Tools', 
    skills: [
      'Git', 'GitHub', 'GitLab', 'Jira/Rally'
    ],
  },
  {
    title: 'Testing & CI/CD', 
    skills: [
      'Cypress', 'Playwright', 'SonarQube', 'Datadog'
    ],
  },
  {
    title: 'Development Tools & IDEs', 
    skills: [
      'Postman', 'Swagger', 'Android Studio', 'IntelliJ', 'JGrasp', 'Eclipse', 'Bash/Zsh'
    ],
  },
  {
    title: 'Hardware & Embedded Systems', 
    skills: [
      'Raspberry Pi', 'Arduino', 'KiCAD'
    ],
  },
];
