import React from "react";
import PageWrapper from "../wrapper";

const Error404 = () => {
  return (
    <PageWrapper>
      Error404
    </PageWrapper>
  );
}

export default Error404;