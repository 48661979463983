import React from "react";
import PageWrapper from "../wrapper";
import { Typography, Box, Grid, Button } from "@mui/material";
import { HOME_BODY, HOME_TITLE } from "../website-text";
import { theme_dark, theme_light } from "../../../theme";
import { useNavigate } from 'react-router-dom';

const Section = (props) => (
  <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '2em'}}>
    <Typography variant={props.variant}>{props.text}</Typography>
  </Box>
)

const Home = () => {
  const navigate = useNavigate();

  const item_style = { padding: '1em' };
  const button_style = { 
    backgroundColor: theme_light, 
    color: theme_dark,
    ':hover': {
      color: theme_light,
      backgroundColor: theme_dark, 
    },
    fontWeight: 'bold',
    width: '100%',
    height: '4em',
  };

  const NS = [
    {id: 1, link: '/about', text: 'About'},
    {id: 2, link: '/skills', text: 'Skills'},
    {id: 3, link: '/experience', text: 'Experience'},
    {id: 4, link: '/projects', text: 'Projects'},
    {id: 5, link: '/contacts', text: 'Contacts'},
  ];

  const NS_length = NS.length; 
  let NS_last_id = -1;
  if (NS_length % 2 === 1) NS_last_id = NS[NS_length - 1].id;

  return (
    <PageWrapper>
      <Section text={HOME_TITLE} variant="h3" />
      <Section text={HOME_BODY} variant="p" />
      <Grid container>
        {NS.map(sec => {
          const key = sec.id;
          const xs_size = 12;
          const md_size = (NS_last_id !== -1 && NS_last_id === key)? 12 : 6;
          return (
            <Grid item key={key} sx={item_style} xs={xs_size} md={md_size}>
              <Button 
                sx={button_style}
                onClick={() => navigate(sec.link)}  
                variant="filled"
              >{sec.text}</Button>
            </Grid>
          )
        })}
      </Grid>
    </PageWrapper>
  );
}

export default Home;