import React from "react";
import { Box } from "@mui/material";
import { theme_dark, theme_light } from "../../theme";

const PageWrapper = (props) => {
  const justifyContent = props.align 
  return (
    <Box 
      sx={{
        p: 4, 
        width: '100%', 
        backgroundColor: theme_dark,
        color: theme_light,
        display: 'flex',
        justifyContent: (justifyContent)? justifyContent : 'center',
      }}
    >
      <Box sx={{maxWidth: '70em'}}>{props.children}</Box>
    </Box>
  );
}

export default PageWrapper;