import React from "react";
import PageWrapper from "../wrapper";
import { Box, Card, CardContent, Link, Typography } from "@mui/material";
import { theme_dark, theme_light } from "../../../theme";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { projects_txt, projects } from "../website-text";

const Projects = () => {
  return (
    <PageWrapper>
      <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '2em'}}>
        <Typography variant="h3">Projects</Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', textAlign: 'start', marginBottom: '2em'}}>
        <Typography sx={{marginBottom: '2em'}}>{projects_txt}</Typography>
        {projects.map(project => (
          <Card key={project.name} sx={{color: theme_dark, backgroundColor: theme_light, marginBottom: '1em'}}>
            {/* <CardContent>
              <Typography variant="h5">{project.name}</Typography>
              <Typography sx={{fontSize: 'small', marginBottom: '1em'}}>{`(${project.time})`}</Typography>
              <Typography>{project.description}</Typography>
              {exp.accomplished.map(item => (
                <Typography sx={{display: 'flex', alignItems: 'center', margin: '5px 0'}}><ArrowRightIcon/>{item}</Typography>
              ))}
            </CardContent> */}
          </Card>
        ))}
      </Box>
    </PageWrapper>
  );
}

export default Projects;