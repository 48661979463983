import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { theme_dark, theme_light } from '../../theme';

export default function Help(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => props.setOpen(false);

  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={handleClose}>
      <DialogTitle> {"Here's some information that might be useful"} </DialogTitle>
      
      <DialogContent>
        <DialogContentText>
          {"(Coming Soon...)"}
        </DialogContentText>
      </DialogContent>
      
      <DialogActions>
        <Button autoFocus sx={{color: theme_dark}} onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}