import React from "react";
import PageWrapper from "../wrapper";
// import me from '../../../assets/1578752518187.jfif';
import me from '../../../assets/Me.jpeg'
import { Box, Typography } from "@mui/material";
import { paragraph_1, paragraph_2, paragraph_3, paragraph_4 } from "../website-text";
import Name from "./test";

const About = () => {
  return (
    <PageWrapper>
      <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Box 
          sx={{
            width: '20vw', 
            height: '20vw', 
            overflow: 'hidden', 
            borderRadius: '10vw',
            margin: '3em 0'
          }}
        >
          <img src={me} alt="Picture of Myself" width='100%' height='100%' />
        </Box>
        <Typography variant="h4" sx={{ marginBottom: '1em'}}>Marzuk Mashrafe</Typography>
        {/* <Name/> */}

        <Typography sx={{textAlign: 'center', marginBottom: '2em'}}>{paragraph_1}</Typography>
        <Typography sx={{textAlign: 'center', marginBottom: '2em'}}>{paragraph_2}</Typography>
        <Typography sx={{textAlign: 'center', marginBottom: '2em'}}>{paragraph_3}</Typography>
        <Typography sx={{textAlign: 'center', marginBottom: '2em'}}>{paragraph_4}</Typography>
      </Box>
    </PageWrapper>
  );
}

export default About;