import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { theme_dark, theme_light } from '../../theme';
import { route_options } from '../../routes';
import { useNavigate } from 'react-router-dom';

export default function Sidebar (props) {
  
  const navigate = useNavigate();

  const listItemSx = { display: 'block' };
  const listItemButtonSx = {
    minHeight: 48, 
    justifyContent: props.open ? 'initial' : 'center', 
    px: 2.5,
    ':hover': {
      backgroundColor: theme_light,
      color: theme_dark,
      'svg': {'fill': `${theme_dark} !important`} // To change Icon Colour when hovering on button
    },
  };
  const listItemButtonSxHighLighted = {
    ...listItemButtonSx,
    backgroundColor: theme_light,
    color: theme_dark,
    'svg': {'fill': `${theme_dark} !important`}, // To change Icon Colour when hovering on button
  };

  const iconSx = { color: theme_light };
  const listItemIconSx = {
    minWidth: 0, 
    mr: props.open ? 3 : 'auto', 
    justifyContent: 'center',
  };
  const listItemTextSx = { opacity: props.open ? 1 : 0 };

  return (
    <List sx={{padding: 0}}>
      {route_options.map((data, index) => {
        const selected = (data.route !== window.location.pathname);

        return (
          <React.Fragment key={data.title}>
            <ListItem disablePadding sx={listItemSx}>
              <ListItemButton sx={selected? listItemButtonSx: listItemButtonSxHighLighted} onClick={() => navigate(data.route)}>
                <ListItemIcon sx={listItemIconSx}>
                  <data.icon sx={iconSx} />
                </ListItemIcon>
                <ListItemText primary={data.title} sx={listItemTextSx} />
              </ListItemButton>
            </ListItem>
            <props.divider/>
          </React.Fragment>
        )
      })}
    </List>
  );
}