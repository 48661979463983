import React from "react";
import PageWrapper from "../wrapper";
import { 
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemIcon
} from "@mui/material";
import {
  CONTACTS,
  EMAIL,
  CONTACT_TEXT_PARAGRAPH_1,
  CONTACT_TEXT_PARAGRAPH_2,
  CONTACT_TEXT_PARAGRAPH_3,
} from "../website-text";
import { theme_dark, theme_light } from "../../../theme";

const ListContact = (link, text, icon, index) => {
  return (
    <ListItemButton 
      key={index}
      rel="noreferrer" 
      target="_blank" 
      href={link} 
      sx={{ 
        backgroundColor: theme_light,
        color: theme_dark, 
        padding: '1em', 
        margin: '5px', 
        "&:hover": { 
          backgroundColor: theme_dark, 
          color: theme_light, 
          "& .MuiListItemIcon-root": { color: theme_light } 
        }
      }}
    >
      <ListItemIcon sx={{color: theme_dark}} >{icon}</ListItemIcon>
      {text}
    </ListItemButton>
  )
}

const Contacts = () => {
  return (
    <PageWrapper>
      <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '2em'}}>
        <Typography variant="h3">Contacts</Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', textAlign: 'start', marginBottom: '2em'}}>
        <Typography sx={{marginBottom: '2em'}}>{CONTACT_TEXT_PARAGRAPH_1}</Typography>
        <Typography sx={{marginBottom: '2em'}}>{CONTACT_TEXT_PARAGRAPH_2}</Typography>
        <Typography sx={{marginBottom: '2em'}}>Email: {EMAIL}</Typography>

        <List component="div" sx={{ width: '100%', bgcolor: theme_dark, marginBottom: '2em', padding: 0 }}>
          {CONTACTS.map((contact, index) => ListContact(contact.link, contact.text, contact.icon, index))}
        </List>
        
        <Typography sx={{marginBottom: '2em'}}>{CONTACT_TEXT_PARAGRAPH_3}</Typography>
      </Box>
    </PageWrapper>
  );
}

export default Contacts;