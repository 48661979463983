import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import PageWrapper from "../wrapper";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SKILLSET } from '../website-text'


const Skills = () => {
  return (
    <PageWrapper >
      <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '2em'}}>
        <Typography variant="h3">{"Skills (and Education)"}</Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', textAlign: 'start', marginBottom: '2em'}}>
        <Typography variant="h4" sx={{marginBottom: '5px'}}>Education</Typography>
        <Typography>
          Candidate for Bachelors of Computer Science from the University of Waterloo
        </Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', textAlign: 'start'}}>
        <Typography variant="h4" sx={{marginBottom: '5px'}}>Skills</Typography>
        <Typography sx={{marginBottom: '1em'}}>
          Some of the skills that I have gathered over the years through either
          my co-op experiences, personal projects or university courses. 
          You can find more on how I have aquired them by going through my 
          projects or experiences.
        </Typography>
        <Grid container>
          {SKILLSET.map(section => (
            <Grid item xs={12} sm={12} md={4} key={section.title} sx={{marginBottom: '1em'}}>
              <Typography variant="h5" sx={{marginBottom: '5px'}}>{section.title}</Typography>
              {section.skills.map(skill => (
                <Typography key={skill} sx={{display: 'flex', alignItems: 'center'}}><ArrowRightIcon/>{skill}</Typography>
              ))}
            </Grid>
          ))}
        </Grid>
      </Box>
    </PageWrapper>
  );
}

export default Skills;